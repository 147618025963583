@import url('https://fonts.googleapis.com/css?family=Quicksand:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Raleway:regular,bold,italic&subset=latin,latin-ext');


/*Main*/
.site-content {
    font-family: Quicksand, serif;
    /*background-color: #fff*/
    /*background: rgb(66,18,92);*/
    /*background: linear-gradient(90deg, rgba(66,18,92,1) 0%, rgb(45, 13, 68) 100%);}*/
}

.site-header-inner {
    height: 120px !important;
    align-items: end !important;
    padding-bottom: 30px
}

@media (max-width: 820px) {
    .site-header-inner {
        padding-bottom: 36px
    }
}

.header-logo {
    height: 50px;
    position: absolute;
    top: 50px;
}

.full-screen-height {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.grabbi-color-1 {
    color: #42125C
}

.grabbi-color-2 {
    color: #CEA7E0
}

.grabbi-color-3 {
    color: #6C4480
}

.grabbi-color-4 {
    color: yellow
}

h1, h2, h3, h4, strong {
    font-family: Quicksand, sans-serif;
}

h1 {
    font-size: 42px !important;
    margin-bottom: 1.5rem !important;
}

h4 {
    margin-bottom: 1rem !important;
    font-size: 1.5rem !important;
}

@media (max-width: 480px) {
    h1 {
        font-size: 38px !important;
        text-align: center !important;
    }
    h4 {
        text-align: center !important;
    }
}

h2 span {
    font-family: "Raleway", sans-serif !important;
}

section {
    position: relative;
    z-index: 1;
}

.header-nav a:not(.button) {
    padding: 0;
    margin-left: 32px;
}

.hol-dir-die-app {
    border-radius: 4px;
}

.header-nav-inner ul li .shop-publish img {
    height: 20px;
    margin-left: 8px;
}

.header-nav-inner ul li .shop-publish span {
    font-size: 18px;
}

/*HEADER*/
.site-header {
    background: linear-gradient(355deg, rgba(66, 18, 92, 1) 0%, rgb(45, 13, 68) 100%, rgba(66, 18, 92, 1) 0%);
}

.language-dropdown {
    margin-left: 30px;
    background: none;
    border: none;
    color: #fff;
    font-family: Quicksand, serif;
    text-align: center;
    display: block;
    height: 100%;
}

.language-dropdown select {
    background-color: #42125C;
    padding: 0;
    margin: 0;
}

.language-dropdown option {
    color: #fff;
    font-family: Quicksand, serif;
    background-color: #42125C;
    width: 100%;
}

/*HERO*/

.hero-main {
    padding-top: 120px;
    z-index: 2;
}

.hero-inner h2 {
    color: #3D3D3D;
}

.hero_logo {
    max-height: 250px;
    margin-bottom: 2.5rem;
}

#hero .arrow-image {
    position: absolute;
    top: -50px;
    right: 30px;
    max-width: 200px;
}

@media (max-width: 1400px) {
    #hero .arrow-image {
        display: none;
    }
}

.grabbi-intro-image {
    /*position: absolute;*/
    bottom: 0;
    max-height: 65vh;
    z-index: 2;
    margin-bottom: 4rem;
}

.grabbi-intro-image-sm {
    height: 40vh;
}

.background-hero-header {
    background-image: linear-gradient(to right top, #2d0d44, #320e4a, #371050, #3d1156, #42125c);
    min-height: calc(100vh - 80px);
    display: flex;
    animation: gradient 5s ease-in infinite;
    align-items: center;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(61, 94, 172, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background-hero-header-content {
    align-self: center;
}

.background-hero-header-row {
    margin: auto;
}

@media (min-width: 1200px) {
    .background-hero-header-row {
        /*max-width: 75vw;*/
    }
}

.background-hero-header h1 {
    max-width: 800px;
    margin: auto;
    margin-bottom: 30px;
}

.header-hero-text {
    font-family: "Raleway", serif;
}

#text-rotate-div h2 {
    font-size: 36px;
}

.app-showcase-image img {
    max-height: 430px;
}

@media (max-width: 992px) {
    .app-showcase-image img {
        max-height: 50vh;
        margin-bottom: 40px !important;
    }
}

.mobile-store-icons {
    /*max-width: 600px !important;*/
    margin-top: 2em;
}

.mobile-store-icons img {
    height: 45px;
}

.hero-bss {
    max-width: 800px;
    margin: auto !important;
}


/*Brands section*/

.brands-section-container {
    padding: 4rem 0;
    background-image: linear-gradient(to right top, #2d0d44, #320e4a, #371050, #3d1156, #42125c);
}

.brands-showcase-scroll-content {
    height: 50vh;
    max-width: 960px;
    overflow: hidden;
    scroll-behavior: smooth;
    margin-left: auto;
    margin-right: auto;
}

.brands-section-container button {
    align-self: center;
    background: yellow;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-family: Quicksand, sans-serif;
    -webkit-box-shadow: 5px 5px 21px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 21px 5px rgba(0, 0, 0, 0.3);
}

/*HOW IT WORKS*/

.features-split h2 {
    color: #3D3D3D;
}

.split-item img {
    height: 50vw;
    max-height: 60vh;
    min-height: 420px;
    display: block;
}

.how-it-works-container {
    max-width: 1200px;
    margin: auto;
    justify-content: center;
}

.image-how-it-works-cycle {
    max-height: 67vh;
    width: auto;
    margin: auto;
}

.how-it-works-row-section {
    flex: 1
}

.how-it-works ul {
    font-family: "Raleway", serif;
    list-style-type: none;
    padding: 0;
}

.how-it-works ul li {
    padding-bottom: 20px;
}

.how-it-works ul li h2 {
    font-weight: bold;
    font-family: "Raleway", serif;
}


/*TESTIMONIAL*/
.testimonial-main h1, h2, p {
    color: #ffffff
}

.testimonial-item-link a {
    color: yellow !important
}

.testimonial-item-content p, span {
    font-family: Quicksand, sans-serif;
}


/*MAILCHIMP FORM*/

.subscribe-newsletter .container.signup-container {
    background-color: #fff;
    border-radius: 12px;
    max-width: 75vw;
    padding: 40px 20px 0 20px;
    -webkit-box-shadow: -10px 13px 20px -4px rgba(0, 0, 0, 0.25);
    box-shadow: -10px 13px 20px -4px rgba(0, 0, 0, 0.25);
}

.subscribe-newsletter .container.signup-container h3 {
    color: #3D3D3D;
}

#mc_embed_signup {
    background: #fff;
    clear: left;
    font: 14px Helvetica, Arial, sans-serif;
}

.subscribe-newsletter, #vendors-banner {
    padding: 40px 0;
    background: linear-gradient(-45deg, #42125c, #6ea997, #532669, #ff3a1a);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.subscribe-newsletter-input {
    border-radius: 6px;
    display: flex;
    justify-content: center;
}

.subscribe-newsletter-input input {
    border: none;
    border-radius: 6px 0 0 6px;
    height: 50px;
    font-family: Quicksand, sans-serif;

}

.subscribe-newsletter-input button {
    min-width: 100px;
    background: black;
    color: #fff;
    font-family: Quicksand, sans-serif;
    border: none;
    border-radius: 0 6px 6px 0;
    height: 50px;
}

.subscribe-newsletter-messages {
    /*max-width: 420px;*/
    margin: 20px auto;
    text-align: center;
    padding-top: 20px;
}

.MC_message_error {
    color: red;
}

.MC_message_error a {
    color: blue;
    display: block;
}


.footer-navigation ul li {
    margin-bottom: 10px;
    text-align: center;
}

footer .footer-images-bss {
    margin: auto auto 20px auto;
    /*max-height: 60px;*/
    flex: 1;
    border-radius: 6px;
    background-color: #fff;
    padding: 10px;
    max-height: 80px;
}

.subscribe-newsletter .download-the-app {
    margin: 5em auto;
}

.subscribe-newsletter .download-the-app img {
    max-height: 60vh;
    margin: auto
}

@media (max-width: 860px) {
    .subscribe-newsletter .download-the-app {
        margin: 2em auto;
    }
}

@media (max-width: 768px) {
    .subscribe-newsletter .download-the-app img {
        max-height: 60vh;
        margin: 0 auto 2em auto;
        max-width: 65vw
    }
}

/*LAYOUT PAGES*/

#layout-pages p {
    color: #3D3D3D;
}

#layout-pages a {
    color: dodgerblue;
}

#layout-pages header a {
    color: #fff
}

#layout-pages h1 {
    margin-top: 20px;
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;

}

/*FEATURE TILES CARDS*/

#feature-tiles .container {
}

#feature-tiles h1, h3 {
    color: #fff;
}

:root {
    --surface-color: #fff;
    --curve: 40;
}

* {
    box-sizing: border-box;
}

/*#what-we-do .bg-overlay{*/
/*    background-image: url("assets/images/bg_overlay_1.png");*/
/*    background-size: cover;*/
/*    position: absolute;*/
/*    bottom: -100px;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    opacity: 0.1;*/
/*}*/

#what-we-do {
    height: 100%;
    width: 100%;
}

#what-we-do .img-bg {
    height: auto;
    /*width: 100%;*/
    top: 1400px;
    position: absolute;
    opacity: 0.1;
}

#what-we-do .img-columns {
    height: 100px;
    width: auto;
    margin: 30px auto;
}

#what-we-do .txt-columns {
    max-width: 300px;
    margin: auto;
}

#what-we-do .txt-columns h4 {
    color: #42125C;
    margin-bottom: 1rem !important;
}

#what-we-do .txt-columns p {
    text-align: justify;
}

@media (min-width: 992px) {
    #what-we-do .txt-columns h4 {
        min-height: 72px
    }
}
@media (max-width: 1640px) and (min-width: 1201px) {
    #what-we-do .img-bg {
        transform: scale(1.8);
    }
}

@media (max-width: 1200px) and (min-width: 992px) {
    #what-we-do .img-bg {
        top: 600px;
        transform: scale(1.8);
    }
}

@media (max-width: 992px) {
    #what-we-do .img-bg {
        top: 1120px;
        transform: scale(2);

    }
}

@media (max-width: 768px) {
    #what-we-do .img-bg {
        top: 1220px;
        transform: scale(2);

    }
}

@media (max-width: 640px) {
    #what-we-do .img-bg {
        display: none;

    }
}

#what-we-do body {
    font-family: 'Quicksand', sans-serif;
    background-color: #fef8f8;
}

#what-we-do .what-we-do-bullets span {
    font-size: 22px;
}

#what-we-do .cards {
    /*display: grid;*/
    /*grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));*/
    /*gap: 2rem;*/
    /*!*margin: 4rem 5vw;*!*/
    /*padding: 0;*/
    /*list-style-type: none;*/
    display: flex;
    /*margin: 4rem 5vw;*/
    padding: 0;
    list-style-type: none;
    gap: 2rem;
}

#what-we-do .card {
    position: relative;
    display: block;
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
    background-image: linear-gradient(to right top, #2d0d44, #320e4a, #371050, #3d1156, #42125c);
    margin: auto;
    width: 180px;
    height: 180px;
}

#what-we-do .card__image {
    width: 50%;
    height: auto;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.9;
    /*padding: 20px 50px;*/
}

#what-we-do .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(var(--curve) * 1px);
    background-color: var(--surface-color);
    transform: translateY(100%);
    transition: .2s ease-in-out;
}

#what-we-do .card:hover .card__overlay {
    transform: translateY(0);
}

#what-we-do .card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 14px 1em 0 1em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
}

#what-we-do .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
}

#what-we-do .card__arc path {
    fill: var(--surface-color);
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

#what-we-do .card:hover .card__header {
    transform: translateY(0);
}

#what-we-do p,
#what-we-do h3 {
    color: #3D3D3D;
}

#what-we-do .card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

#what-we-do .card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: #6A515E;
    font-weight: bold;
}

#what-we-do .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "Quicksand";
    font-size: .8em;
    color: #D7BDCA;
}

#what-we-do .card__status {
    font-size: .8em;
    color: #D7BDCA;
}

#what-we-do .card__description {
    padding: 0 5px 3em;
    margin: 0;
    color: #3d3d3d;
    font-family: "Quicksand";
    font-size: 16px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

/*WHO WE ARE*/

#who-we-are p,
#who-we-are h1,
#who-we-are h2,
#who-we-are h3,
#who-we-are h4 {
    color: #fff;
}

#who-we-are .h1-titles {
    color: black;
    background-color: rgba(206, 167, 224, 0.6);
    line-height: 55px;
    padding: 0 20px
}

#who-we-are-images .container-stack {
    padding: 100px 30px;
    width: 100%;
    margin: 0 auto;
    max-width: 900px;
}

#who-we-are-images .image-stack {
    max-width: 35vw;
}

#who-we-are-images .image-stack img {

}

@media (max-width: 520px) {
    #who-we-are-images .image-stack {
        max-width: 60vw;
    }
}

/* HOW IT WORKS */
#how-it-works {
    background-color: #fff;
    min-height: 800px;
}

#custom-card body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #260b36;
}

#custom-card.container {
    position: relative;
}

#custom-card.container .card {
    position: relative;
    height: 500px;
    width: 30vw;

    min-width: 220px;
    min-height: 340px;

    max-width: 320px;
    background: #260b36;
    border-radius: 20px;
    overflow: hidden;
    margin: auto;
}

@media (max-width: 576px) {
    #custom-card.container .card {
        width: 100%;
        max-width: 75vw;
        height: 100vw;
    }
}

#custom-card.container .card:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right top, #2d0d44, #6C4480);
    clip-path: circle(150px at 80% 20%);
    transition: 0.5s ease-in-out;
}

#custom-card.container .card:hover:before {
    clip-path: circle(300px at 80% -20%);
}

#custom-card.container .card:after {
    position: absolute;
    top: -25%;
    left: -30%;
    font-size: 20em;
    font-weight: 800;
    font-style: italic;
    color: rgba(255, 255, 0, 0.09)
}

#custom-card.container.first .card:after {
    content: '1';
}

#custom-card.container.second .card:after {
    content: '2';
}

#custom-card.container.third .card:after {
    content: '3';
}

#custom-card.container .card .imgBx {
    position: absolute;
    top: 64%;
    left: 10%;
    transform: translateY(-70%);
    z-index: 10000;
    width: 100%;
    height: 220px;
    transition: 0.5s;
}

#custom-card.container .card:hover .imgBx {
    top: 0%;
    transform: translateY(28%);
}

#custom-card.container .card .imgBx img {
    position: absolute;
    top: 30%;
    left: 46%;
    /*transform: translate(-50%, -50%) rotate(-25deg);*/
    transform: translate(-50%, -50%);
    width: 220px;
}

@media (max-width: 480px) {
    #custom-card.container .card .imgBx img {
        width: 200px;
        transform: translate(-50%, -60%);
    }

    #custom-card.container .card:hover .imgBx {
        transform: translateY(10%);
    }
}

#custom-card.container .card .contentBx {
    position: absolute;
    bottom: -40px;
    width: 100%;
    height: 100px;
    text-align: center;
    transition: 1s;
    z-index: 10;
}

#custom-card.container .card .contentBx h2 {
    font-size: 32px;
    bottom: 10px;
}

#custom-card.container .card:hover .contentBx {
    height: 210px;
}

#custom-card.container .card .contentBx h2 {
    position: relative;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    margin: 0;
}

#custom-card .container .card .contentBx .size h3,
#custom-card.container .container .card .contentBx .color h3 {
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 10px;
}

#custom-card.container .card .contentBx p {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    color: #fff;
    opacity: 0;
    transform: translateY(50px);
    transition: 0.5s;
    margin-top: 0;
}

#custom-card.container .card:hover .contentBx p {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 0.75s;

}

/*clearfix*/

#who-we-are-images .image-stack::after {
    content: ' ';
    display: table;
    clear: both;
}

#who-we-are .container-stack img {
    border-radius: 8px;
    box-shadow: inset 0px 0px 40px 40px #DBA632;
}

#who-we-are-images .image-stack__item--top {
    /*float: left;*/
    width: 25vw;
    min-width: 280px;

    /*margin-right: -100%;*/
    /*padding-top: 15%;*/
    position: relative;
    /*z-index: 1;*/
    /*top: 230px*/
}

#who-we-are-images .image-stack__item--bottom {
    /*float: right;*/
    width: 27vw;
    min-width: 280px;
    position: relative;
    bottom: 0px;
}


/*@media (max-width: 768px) {*/
/*    #who-we-are-images .image-stack__item--bottom {*/
/*        float: right;*/
/*        width: 75%;*/
/*        position: relative;*/
/*        bottom: 160px;*/
/*    }*/

/*}*/

#who-we-are-images img {
    width: 100%;
}


/*BECOME A PARTNER*/
#become-a-partner .intro-container {
    width: 100vw;
    /*height: calc(100vh - 80px);*/
    height: 100vh;
    /*margin-top: 120px;*/
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-color: #42125C;
    overflow: hidden
}

#become-a-partner .intro-container-bg {
    width: 100%;
    /*height: calc(100vh - 80px);*/
    height: 100vh;
    /*margin-top: 120px;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
}

#become-a-partner .intro-container-bg h1 {
    font-weight: 600;
    -webkit-text-stroke: 0.2px #42125C;
}

@media (max-width: 520px) {
    #become-a-partner .intro-container-bg h1 {
        font-size: 32px !important;
    }
}

#become-a-partner .intro-container-bg button {
    padding: 5px 30px;
    background-color: #42125C;
    border: 1px solid yellow;
    color: yellow;
    border-radius: 50px;
    font-weight: bold;
    font-size: 24px;
}

#become-a-partner video {
    position: absolute;
    left: 0;
    width: 100vw;
    height: -moz-calc(100vh - 80px);
    height: -webkit-calc(100vh - 80px);
    height: calc(100vh - 80px);
    zIndex: 1;
    /*transform: translate(-50%, -50%);*/
}

#become-a-partner .black-overlay {
    position: absolute;
    left: 0;
    height: -moz-calc(100vh - 80px);
    height: -webkit-calc(100vh - 80px);
    height: calc(100vh - 80px);
    width: 100vw;
    zIndex: 1;
    background-color: black;
    opacity: 0.5;
}

@media (min-aspect-ratio: 16/9) {
    #become-a-partner .video-container video {
        /* height = 100 * (9 / 16) = 56.25 */
        height: 56.25vw;
    }
}

@media (max-aspect-ratio: 16/9) {
    #become-a-partner .video-container video {
        /* width = 100 / (9 / 16) = 177.777777 */
        width: 177.78vh;
    }
}

#become-a-partner .header-overlay {
    left: 0;
    width: 1000px;
    margin: auto;
    right: 0;
}

#become-a-partner #vendors-how-it-works .card {
    position: relative;
    height: 420px;
    width: 275px;
    min-height: 340px;
    background: #260b36;
    border-radius: 20px;
    overflow: hidden;
    margin: auto;
}

#become-a-partner #vendors-how-it-works .card .vendor-how-to-box {
    height: 65%;
    max-height: 370px;
    overflow: hidden;
    position: relative;
}
#become-a-partner #vendors-how-it-works .card .vendor-how-to-box  h2{
    text-align: center;
    width: 290px;
    position: absolute;
    color: #fff;
    bottom: 0px;
    left: -8px;
    font-size: 28px;
    font-weight: bold;
    -webkit-text-stroke: 0.2px black;
}

#become-a-partner #vendors-how-it-works .card .vendor-how-to-box  h2 span {
   font-weight: bold;
   font-family: Quicksand, sans-serif;
}
#become-a-partner #vendors-how-it-works .card .vendor-how-to-box img {
    width: 100%;
    height: auto;
    margin: auto;
    position: absolute;
    bottom: -26px
}
#become-a-partner #vendors-how-it-works .card .vendor-how-to-content-box p {
    background: none;
    margin: 0
}
#become-a-partner #vendors-how-it-works .card .vendor-how-to-content-box {
    text-align: center;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0 20px;
}
#become-a-partner #vendors-how-it-works .card .vendor-how-to-content-box p {
    text-align: center
}

/*IMPRESSUM*/

#impressum h1,
#impressum h2,
#impressum h3,
#impressum h4 {
    color: #3D3D3D;
}


/* CTA VENDORS BANNER */
#vendors-banner {
    height: 30vh;
    min-height: 250px;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

#vendors-banner .container {
    max-width: 1200px;
}

#vendors-banner h2 {
    font-weight: 600;
    font-family: Quicksand, sans-serif;
    color: #fff;
    margin-bottom: 1rem;
    padding-left: 0;
    font-size: 42px;
}

#vendors-banner button {
    padding: 10px 20px;
    background-color: yellow;
    color: #3D3D3D;
    border-radius: 20px;
    max-width: 240px;
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    border: none
}

#vendors-banner img {
    height: 180px;
    width: auto
}

@media (max-width: 1200px) {
    #vendors-banner {
        height: 40vh;
    }
}

@media (max-width: 992px) {
    #vendors-banner button {
        margin: auto;
    }
    #vendors-banner {
        height: 30vh;
        min-height: 460px;
    }
}

@media (max-width: 820px) {
    #vendors-banner {
        height: 50vh;
        min-height: 460px;
    }
}

#vendors-how-it-works,
#vendors-how-it-works h1,
#vendors-how-it-works h4,
#vendors-how-it-works p {
    background: #42125C;
    color: #fff
}

#cta-vendors button {
    background: #42125C;
    color: yellow;
    padding: 10px 30px;
    border: none;
    border-radius: 50px;
    font-weight: 600
}